export function initScaleImages() {
    $('figure.image, .photos .item div.image').each(function (_, figure) {
        var $figure = $(figure);
        var $img = $figure.find("img");
        onImgLoad($img, function () {
            var height = $img[0].naturalHeight;
            var width = $img[0].naturalWidth;
            if (width < $figure.outerWidth() && height < $figure.outerHeight()) {
                // image too small for this container
                // don't enlarge it
                $img.css('object-fit', 'none');
            }
        });
    });
}

function onImgLoad(selector, callback) {
    $(selector).each(function () {
        if (this.complete) {
            callback.apply(this);
        }
        else {
            $(this).on('load', function () {
                callback.apply(this);
            });
        }
    });
};