import $ from 'jquery';
/*
 * USAGE
 * Add class 'toggle' in toggle button element.
 * Add class 'hidden' or 'visible' in toggle button element.
 * 
 * TOGGLES
 * DD tags using DT, TR TD tags using TR TH, nextElementSibling using anything
 * 
 * SIDENOTE
 * You are not allowed (by standards) to nest e.g. a H4 tag in a DT tag.
 * 
 * EXAMPLES
 * + Toggle TDs in a table using TH as button
 * <table>
 * 	<tr><th class="toggle hidden">Toggle Button</th></tr>
 * 	<tr><td>This can be toggled</td></tr>
 * 	<tr><td>This can also be toggled</td></tr>
 *  <tr><th>Not a toggle button</th></tr>
 *  <tr><td>This cannot be toggled</td></tr>
 * </table>
 * 
 * + Toggle DDs in DL using DT as button
 * <dl>
 * 	<dt class="toggle hidden">Toggle button</dt>
 * 	<dd>This can be toggled</dd>
 * 	<dd>This can also be toggled</dd>
 * 	<dt>No toggle button here</dt>
 * 	<dd>This cannot be toggled</dt>
 * </dl>
 * 
 * + Toggle nextElementSibling (or use data-parent and data-target)
 * ++ Example 1
 * <div class="helptext-button toggle hidden">Toggle button</div>
 * <div class="helptext">This can be toggled</div>
 * ++ Example 2
 * <h4 class="toggle visible">Toggle button</h4>
 * <table><tr><td>This table can be toggled in its entirety and by default it is visible.</td></tr></table>
 */

function toggleExpandedAria($element, hide) {
    $element.attr('aria-expanded', function (i, attr) {
        return hide ? 'false' : (attr == 'true' ? 'false' : 'true')
    });
}
export default function initToggle() {
    // Hide toggle hidden elements on start
    $('.toggle.hidden').each(function (_, element) { doToggle($(element), 'hide'); });
    // Show arrow on toggle visible elements on start
    $('.toggle.visible').each(function (_, element) { toggleExpandedAria($(element)); });

    // Toggle next elements when element is clicked
    $('.toggle').click(function () { doToggle($(this), 'toggle'); });

    $('.click-other').click(function () {
        var $element = $(this);
        var data = $element.data();
        var $parent = (data['parent'] && $element.closest(data['parent'])) || $;
        var $target = (data['target'] && $parent.find(data['target'])) || $element.next();
        $target.click();
    });

}

function doToggle($element, action) {
    if (action == 'toggle') { toggleExpandedAria($element); }
    if (action == 'hide') { toggleExpandedAria($element, true); }
    // Toggle button: TR with TH, Object: TRs with TDs under TR TH
    if ($element.is('th')) {
        $element = $element.parent().next('tr');
        while ($element && !$element.children(':first').is('th')) {
            if (action == 'hide') { $element.hide(); }
            else { $element.slideToggle(); }
            $element = $element.next('tr');
        }
    }
    // Toggle button: DT, Object: DDs under DT
    else if ($element.is('dt')) {
        // Toggle or Hide the DDs under the selected DT
        $element = $element.next();
        while ($element && $element.is('dd')) {
            if (action == 'hide') { $element.hide(); }
            else { $element.slideToggle(); }
            $element = $element.next();
        }
    }
    // Toggle button: None of the above, Object: nextElementSibling
    else {
        var data = $element.data();
        var $parent = (data['parent'] && $element.closest(data['parent'])) || $;
        var $target = $((data['target'] && $parent.find(data['target'])) || $element.next());
        if (action == 'hide') { $target.hide(); }
        else {
            // filters are hidden on mobile
            if ($target.is('.is-hidden-mobile')) {
                $target.removeClass('is-hidden-mobile');
                $target.hide();
            }
            $target.slideToggle();
        }
        if ($target.is(':visible')) {
            var DANS = window.DANS;
            if (typeof DANS !== 'undefined' && typeof DANS.maybeInitAutoComplete !== 'undefined') {
                $target.find("div.param-field-repeater").each(function (n, el) {
                    // autocomplete is only initialized properly if it is actually visible
                    // use the jQuery in the window, as this contains autocomplete
                    DANS.maybeInitAutoComplete(window.$(el));
                });
            }
        }
    }
}
