//triggered by printbutton in template _emptyFieldsTogle.gsp to only print the text, not the photo carousel 
export function printBox(){
    $('.box').removeClass("is-hidden-print");
    $('.scroller-wrapper').addClass("is-hidden-print");
    window.print();
}

//triggered by printbutton in template _previousNext.gsp to only print the photo carousel, not the text
export function printCarousel(){
    $('.box').addClass("is-hidden-print");
    $('.scroller-wrapper').removeClass("is-hidden-print");
    window.print();
}