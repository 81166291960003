import initCommon from 'memo-common';
import { printBox, printCarousel } from './print';
import { initScaleImages } from './scale-images';

window.printBox = printBox;
window.printCarousel = printCarousel;

$(() => {
    initCommon();
    initScaleImages();
});
